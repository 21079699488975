import React, { Children, cloneElement } from 'react';
import { node } from 'prop-types';
import classNames from 'classnames';
import CSS from './style.scss';

function Heading({ children, ...props }) {
  return (
    <span {...props}>
      {Children.map(children, child =>
        cloneElement(child, {
          className: classNames(CSS.heading, child.props.className),
        }),
      )}
    </span>
  );
}

Heading.propTypes = {
  children: node.isRequired,
};

export default Heading;
