// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loadingWrapper-1GZWA{position:relative;display:flex;justify-content:center;align-items:center;margin-top:20px;height:50px}@media screen and (min-width: 600px){.loadingWrapper-1GZWA{margin-top:0}}@media screen and (min-width: 600px){.noSections-1ewWe{position:fixed;width:100%;height:100%;left:0;top:0}}.loader-Dea2f:after{background:#d3d3d3}\n", ""]);
// Exports
exports.locals = {
	"loadingWrapper": "loadingWrapper-1GZWA",
	"noSections": "noSections-1ewWe",
	"loader": "loader-Dea2f"
};
module.exports = exports;
