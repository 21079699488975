// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".followButton-15DDF{color:white;font-size:14px;font-family:\"GT-Walsheim\",sans-serif;text-align:center;height:28px;line-height:1;padding:0 12px;margin-right:10px;border-radius:calc(28px / 2);background:linear-gradient(to bottom right, #ff1060, #ff6255)}@media (min-width: 600px){.followButton-15DDF{margin-right:0;align-self:center}}\n", ""]);
// Exports
exports.locals = {
	"followButton": "followButton-15DDF"
};
module.exports = exports;
