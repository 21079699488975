// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sections-1oZei{flex:1;position:relative;overflow-x:hidden;-webkit-overflow-scrolling:auto}\n", ""]);
// Exports
exports.locals = {
	"sections": "sections-1oZei"
};
module.exports = exports;
