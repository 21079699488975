import React from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import classNames from 'classnames';
import { Dropdown, DropdownOption, DotsIcon } from '@blendle/lego';
import CSS from './style.scss';

function SectionContextMenuButton() {
  return (
    <div className={CSS.icon}>
      <DotsIcon />
    </div>
  );
}

function SectionContextMenu({ options, onClickOption, onToggleDropdown, className }) {
  return (
    <div className={classNames(CSS.contextMenu, className)}>
      <Dropdown
        triggerButton={SectionContextMenuButton}
        className={CSS.sectionContextDropdown}
        onToggle={onToggleDropdown}
        closeOnItemClicked
      >
        {options.map(option => (
          <DropdownOption key={option.action} onClick={onClickOption(option)}>
            <span
              className={CSS.optionContent}
              dangerouslySetInnerHTML={{ __html: option.label }}
            />
          </DropdownOption>
        ))}
      </Dropdown>
    </div>
  );
}

SectionContextMenu.propTypes = {
  options: arrayOf(shape({ action: string, label: string })).isRequired,
  onClickOption: func.isRequired,
  onToggleDropdown: func.isRequired,
  className: string,
};

SectionContextMenu.defaultProps = {
  className: '',
};

export default SectionContextMenu;
