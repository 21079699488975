import altConnect from 'higher-order-components/altConnect';
import renderNothingIfIsHidden from 'higher-order-components/renderNothingIfIsHidden';
import { compose, branch, renderNothing } from '@blendle/recompose';
import { translate } from 'instances/i18n';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_ERROR,
  STAFFPICKS_CHANNELS,
  FEATURE_CAPABILITY_HAS_ALGORITHMIC_SELECTION,
} from 'app-constants';
import { hasCapability } from 'selectors/capabilities';
import {
  SUPPORTED_SECTION_TYPES,
  SECTION_TYPE_CHANNEL,
  MOBILE_LAYOUT_SCROLLING,
  MOBILE_LAYOUT_WRAPPING,
  SECTION_TYPE_PROVIDER,
  SECTION_TYPE_ENTITY,
  SECTION_TYPE_EDITORIAL,
} from '../constants';
import FeaturedSectionHeader from '../components/FeaturedSectionHeader';
import { getSectionItemIds, isFeaturedSection } from '../selectors/sections';
import PageSectionContainer from '../containers/PageSectionContainer';

export const getSubtitle = function getSubtitle(section) {
  // Some sections have a subtitle specifically for web provided by the api
  if (section.subtitle_web) {
    return section.subtitle_web;
  }

  // Some sections have a subtitle provided by the API
  if (section.subtitle) {
    return section.subtitle;
  }

  const followingState = section.following ? 'following' : 'not_following';

  switch (section.type) {
    case SECTION_TYPE_EDITORIAL:
      return translate('sections.subtitle.staffpicks');
    case SECTION_TYPE_CHANNEL:
      if (STAFFPICKS_CHANNELS.includes(section.channel.id)) {
        return translate('sections.subtitle.staffpicks');
      }

      return translate(`sections.subtitle.entity_${followingState}`);
    case SECTION_TYPE_ENTITY:
      return translate(`sections.subtitle.entity_${followingState}`);
    case SECTION_TYPE_PROVIDER:
      return translate(`sections.subtitle.provider_${followingState}`);
    default:
      return translate(`sections.subtitle.entity_${followingState}`);
  }
};

export const makeMapStateToProps = (stores) => {
  function mapStateToProps({ sectionsState, featureCapabilitiesState }, { sectionId }) {
    const { sections, sectionFeeds } = sectionsState;
    const section = sections.get(sectionId);

    const visibleItemIds = getSectionItemIds(sectionsState, sectionId);

    const {
      scrolledTiles = 0,
      label: title,
      item_uids: itemIds = [],
      type: sectionType,
      following: isFollowing = true,
      personalized,
    } = section || {};

    if (!SUPPORTED_SECTION_TYPES.includes(sectionType)) {
      return { isHidden: true };
    }

    const { status = STATUS_INITIAL, nextUrl } = sectionFeeds.get(sectionId) || {};
    const isLoading = [STATUS_PENDING, STATUS_INITIAL].includes(status) || !visibleItemIds.length;

    const areProviderCardsAllowed = hasCapability(
      featureCapabilitiesState,
      FEATURE_CAPABILITY_HAS_ALGORITHMIC_SELECTION,
    );

    return {
      itemIds: visibleItemIds,
      tilesCount: itemIds.length,
      sectionType,
      status,
      isLoading,
      nextUrl,
      sectionId,
      title,
      subtitle: getSubtitle(section),
      intro: section.intro,
      backgroundColor: section.background_color,
      scrolledTiles,
      mobileLayout: isFollowing ? MOBILE_LAYOUT_WRAPPING : MOBILE_LAYOUT_SCROLLING,
      headerComponent: isFeaturedSection(sectionId) ? FeaturedSectionHeader : undefined,
      sections,
      sectionFeeds,
      sectionConsistsOfProviderCards: personalized,
      areProviderCardsAllowed,
    };
  }

  mapStateToProps.stores = stores;

  return mapStateToProps;
};

function makePageSectionContainer(pageStore, featureCapabilitiesStore, actions) {
  const mapStateToProps = makeMapStateToProps({
    SectionsStore: pageStore,
    FeatureCapabilitiesStore: featureCapabilitiesStore,
  });

  return compose(
    altConnect(mapStateToProps, actions),
    renderNothingIfIsHidden,
    branch(({ status }) => status === STATUS_ERROR, renderNothing),
  )(PageSectionContainer);
}

export default makePageSectionContainer;
