// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".transitionGroup-M_RLq{position:relative;min-height:40px;align-self:flex-end;padding-bottom:10px}.leave-VHoSC{position:absolute;opacity:1;right:0;top:0}.leaveActive-3b35x{opacity:0;transition:opacity 0.2s ease-in}.enter-3x0v2{opacity:0;right:0;top:0}.enterActive-19KOs{opacity:1;transition:opacity 0.2s ease-in}\n", ""]);
// Exports
exports.locals = {
	"transitionGroup": "transitionGroup-M_RLq",
	"leave": "leave-VHoSC",
	"leaveActive": "leaveActive-3b35x",
	"enter": "enter-3x0v2",
	"enterActive": "enterActive-19KOs"
};
module.exports = exports;
