import altConnect from 'higher-order-components/altConnect';
import { compose, withHandlers, lifecycle } from '@blendle/recompose';
import { memoize } from 'lodash';
import Analytics from 'instances/analytics';
import { translate } from 'instances/i18n';
import NotificationsActions from 'actions/NotificationsActions';
import ChannelsStore from 'stores/ChannelsStore';
import AuthStore from 'stores/AuthStore';
import ProviderStore from 'stores/ProviderStore';
import FavoriteProvidersStore from 'stores/FavoriteProvidersStore';
import FavoriteProvidersActions from 'actions/FavoriteProvidersActions';
import LabStore from 'stores/LabStore';
import EntityStore from 'stores/EntityStore';
import EntityActions from 'actions/EntityActions';
import { isFollowingEntity } from 'selectors/entity';
import { getById as getChannelById } from 'selectors/channels';
import { providerById as getProviderById } from 'selectors/providers';
import UserPrefsUpdatedNotification from 'components/notifications/UserPrefsUpdatedNotification';
import { STATUS_OK, STAFFPICKS_CHANNELS, INTERNAL_LOCATION } from 'app-constants';
import {
  CONTEXT_MENU_ACTION_UNFOLLOW_CHANNEL,
  CONTEXT_MENU_ACTION_FOLLOW_CHANNEL,
  CONTEXT_MENU_ACTION_UNFOLLOW_PROVIDER,
  CONTEXT_MENU_ACTION_FOLLOW_PROVIDER,
  CONTEXT_MENU_ACTION_UNFOLLOW_ENTITY,
  CONTEXT_MENU_ACTION_FOLLOW_ENTITY,
  SECTION_TYPE_CHANNEL,
  SECTION_TYPE_PROVIDER,
  SECTION_TYPE_ENTITY,
} from '../constants';
import SectionContextMenuActions from '../actions/SectionContextMenuActions';
import SectionActions from '../components/SectionActions';

function isStaffpicksChannel(channelId) {
  return STAFFPICKS_CHANNELS.includes(channelId);
}

function getChannelOptions(channel) {
  const isFollowing = channel.get('following');
  const channelId = channel.id;

  // Disable follow/unfollow of staffpicks channels
  if (isStaffpicksChannel(channelId)) {
    return [];
  }

  if (isFollowing) {
    return [
      {
        action: CONTEXT_MENU_ACTION_UNFOLLOW_CHANNEL,
        label: translate('sections.dropdown_options.unfollow', [channel.get('full_name')]),
        payload: {
          channelId,
          isFollowing,
        },
      },
    ];
  }

  return [
    {
      action: CONTEXT_MENU_ACTION_FOLLOW_CHANNEL,
      label: translate('sections.dropdown_options.follow', [channel.get('full_name')]),
      payload: {
        channelId,
        isFollowing,
      },
    },
  ];
}

function getProviderOptions(provider, favoriteProviders) {
  const providerId = provider.id;
  const isFollowing = favoriteProviders.includes(providerId);

  if (isFollowing) {
    return [
      {
        action: CONTEXT_MENU_ACTION_UNFOLLOW_PROVIDER,
        label: translate('sections.dropdown_options.unfollow', [provider.name]),
        payload: {
          providerId,
          isFollowing,
        },
      },
    ];
  }

  return [
    {
      action: CONTEXT_MENU_ACTION_FOLLOW_PROVIDER,
      label: translate('sections.dropdown_options.follow', [provider.name]),
      payload: {
        providerId,
        isFollowing,
      },
    },
  ];
}

function getEntityOptions(entityId, entityName, userEntities) {
  const isFollowing = isFollowingEntity(userEntities, entityId);

  if (isFollowing) {
    return [
      {
        action: CONTEXT_MENU_ACTION_UNFOLLOW_ENTITY,
        label: translate('sections.dropdown_options.unfollow', [entityName]),
        payload: {
          entityId,
          entityName,
          isFollowing,
        },
      },
    ];
  }

  return [
    {
      action: CONTEXT_MENU_ACTION_FOLLOW_ENTITY,
      label: translate('sections.dropdown_options.follow', [entityName]),
      payload: {
        entityId,
        entityName,
        isFollowing,
      },
    },
  ];
}

function getDefaultAnalyticsPayload(section, internalLocation) {
  return {
    internal_location: internalLocation,
    location_in_layout: 'sections',
    section_id: section.id,
    section_type: section.type,
  };
}

const memoizeFetchUserStores = memoize((userId) => {
  FavoriteProvidersActions.fetchFavoriteProviders.defer(userId);
  EntityActions.fetchUserEntities.defer(userId);
});

export const makeMapStateToProps = (stores) => {
  function mapStateToProps(
    {
      sectionsState: { sections },
      channelsState,
      providerState,
      favoriteProvidersState,
      labState,
      entityState,
    },
    { sectionId },
  ) {
    const section = sections.get(sectionId);

    // Eventually there'll probably be some default options for each section
    let options = [];
    if (section.type === SECTION_TYPE_CHANNEL) {
      const channelId = section.channel.id;
      const channel = getChannelById(channelsState, channelId);
      options = channel ? getChannelOptions(channel) : options;
    }

    if (section.type === SECTION_TYPE_PROVIDER && favoriteProvidersState.status === STATUS_OK) {
      const providerId = section.provider.id;
      const provider = getProviderById(providerState, providerId);
      options = provider ? getProviderOptions(provider, favoriteProvidersState.favorites) : options;
    }

    if (section.type === SECTION_TYPE_ENTITY) {
      options = getEntityOptions(section.entity.id, section.label, entityState.userEntities);
    }

    return { options, isFollowing: section.following };
  }

  mapStateToProps.stores = stores;

  return mapStateToProps;
};

function makeSectionActionsContainer(store, internalLocation = INTERNAL_LOCATION.TIMELINE_PREMIUM) {
  const mapStateToProps = makeMapStateToProps({
    SectionsStore: store,
    ChannelsStore,
    ProviderStore,
    FavoriteProvidersStore,
    LabStore,
    EntityStore,
  });

  return compose(
    lifecycle({
      componentDidMount: () => {
        // We only want to fetch the stores if they haven't been fetched before. Otherwise each
        // section will send the same request.
        memoizeFetchUserStores(AuthStore.getState().user.id);
      },
    }),
    withHandlers({
      onClickOption: ({ sectionId }) => (option) => () => {
        const { action } = option;
        const notificationId = `section-pref-${sectionId}-${action}-${Date.now()}`;
        const notificationProps = {
          onClick: () => NotificationsActions.hideNotification(notificationId),
        };

        const { sections } = store.getState();
        const section = sections.get(sectionId);

        SectionContextMenuActions.onClickOption(AuthStore.getState().user.id, option, {
          ...getDefaultAnalyticsPayload(section, internalLocation),
          ...(option.analytics || {}),
        });

        NotificationsActions.showNotification(
          UserPrefsUpdatedNotification,
          notificationProps,
          notificationId,
          {
            delay: 100,
            duration: 3000,
          },
        );
      },
      onToggleDropdown: ({ sectionId }) => (isOpened) => {
        const eventName = `Section Dropdown ${isOpened ? 'Opened' : 'Closed'}`;

        const { sections } = store.getState();
        const section = sections.get(sectionId);

        Analytics.track(eventName, getDefaultAnalyticsPayload(section, internalLocation));
      },
    }),
    altConnect(mapStateToProps),
  )(SectionActions);
}

export default makeSectionActionsContainer;
