import React, { Component } from 'react';
import { string, instanceOf, func, oneOf } from 'prop-types';
import PageSection from '../components/PageSection';
import { getTilesUrl, shouldFetchTiles } from '../selectors/sections';
import { SECTION_TYPE_HEADER, SUPPORTED_SECTION_TYPES } from '../constants';
import HeaderSection from '../components/HeaderSection';

export default class PageSectionContainer extends Component {
  static propTypes = {
    sectionId: string.isRequired,
    sectionType: oneOf(SUPPORTED_SECTION_TYPES).isRequired,
    sections: instanceOf(Map).isRequired,
    sectionFeeds: instanceOf(Map).isRequired,
    fetchSectionFeed: func.isRequired,
  };

  componentDidMount() {
    const { sectionId, sections, sectionFeeds, fetchSectionFeed } = this.props;
    const section = sections.get(sectionId);

    if (shouldFetchTiles(section, sectionFeeds)) {
      const tilesUrl = getTilesUrl(section);
      fetchSectionFeed(sectionId, tilesUrl);
    }
  }

  render() {
    const { sectionType } = this.props;

    if (sectionType === SECTION_TYPE_HEADER) {
      return <HeaderSection {...this.props} />;
    }

    return <PageSection {...this.props} />;
  }
}
