// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".featuredSectionHeader-19gyE{padding:0 15px 15px}@media (min-width: 600px){.featuredSectionHeader-19gyE{padding-left:0}}\n", ""]);
// Exports
exports.locals = {
	"featuredSectionHeader": "featuredSectionHeader-19gyE"
};
module.exports = exports;
