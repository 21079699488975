import React, { Component } from 'react';
import { arrayOf, string, func, number } from 'prop-types';
import { NEXT_SECTIONS_TO_LOAD_COUNT } from '../constants';
import PageSections from '../components/PageSections';

class PageSectionsContainer extends Component {
  static propTypes = {
    sectionIds: arrayOf(string).isRequired,
    preloadSection: func.isRequired,
    status: number.isRequired,
    sectionContainer: func.isRequired,
  };

  state = {
    visibleSectionsCount: NEXT_SECTIONS_TO_LOAD_COUNT + 1,
  };

  componentDidMount() {
    this.preloadNextSections();
  }

  componentWillUnmount() {
    window.cancelIdleCallback(this.preloadCallbackId);
  }

  preloadNextSections() {
    this.preloadCallbackId = window.requestIdleCallback(() => {
      const { visibleSectionsCount } = this.state;
      const { sectionIds } = this.props;

      const sectionIdsToPreload = sectionIds.slice(
        visibleSectionsCount,
        visibleSectionsCount + NEXT_SECTIONS_TO_LOAD_COUNT,
      );

      sectionIdsToPreload.forEach(sectionIdToPreload =>
        this.props.preloadSection(sectionIdToPreload),
      );
    });
  }

  showMoreSections = () => {
    this.setState(currentState => ({
      visibleSectionsCount: currentState.visibleSectionsCount + NEXT_SECTIONS_TO_LOAD_COUNT,
    }));
    this.preloadNextSections();
  };

  render() {
    const { sectionIds, status, sectionContainer } = this.props;
    const { visibleSectionsCount } = this.state;

    return (
      <PageSections
        showMoreSections={this.showMoreSections}
        sectionIds={sectionIds.slice(0, visibleSectionsCount)}
        canLoadMoreSections={visibleSectionsCount < sectionIds.length}
        sectionContainer={sectionContainer}
        status={status}
      />
    );
  }
}

export default PageSectionsContainer;
