// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".body-1YO3S,.body-1YO3S>*{font-family:\"GT-Walsheim\",sans-serif;font-size:16px;color:#7f7f7f;line-height:1.4;margin:0 auto 20px auto;max-width:680px;text-align:center}.body-1YO3S a{text-decoration:none}.body-1YO3S li{list-style:disc outside;margin-left:1.1em;text-align:left}@media (min-width: 600px){.body-1YO3S,.body-1YO3S>*{font-size:20px}}\n", ""]);
// Exports
exports.locals = {
	"body": "body-1YO3S"
};
module.exports = exports;
