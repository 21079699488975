// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".providerCardBackground-Nwu8f{border-top:1.5px solid #dadadd;background-color:#f2f2f6}\n", ""]);
// Exports
exports.locals = {
	"providerCardBackground": "providerCardBackground-Nwu8f"
};
module.exports = exports;
