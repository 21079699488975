import React, { Children, cloneElement } from 'react';
import { node } from 'prop-types';
import classNames from 'classnames';
import CSS from './style.scss';

function Body({ children, ...props }) {
  return (
    <span {...props}>
      {Children.map(children, child =>
        cloneElement(child, {
          className: classNames(CSS.body, child.props.className),
        }),
      )}
    </span>
  );
}

Body.propTypes = {
  children: node.isRequired,
};

export default Body;
