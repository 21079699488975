// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".alignment-3ljVo{width:100%;margin:0 auto;padding:20px 15px}@media (min-width: 600px){.alignment-3ljVo{padding:60px 20px;max-width:1120px}}\n", ""]);
// Exports
exports.locals = {
	"alignment": "alignment-3ljVo"
};
module.exports = exports;
