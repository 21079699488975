import React from 'react';
import Error from 'components/Application/Error';
import { translate } from 'instances/i18n';

function SectionsPageError() {
  const message = (
    <p
      dangerouslySetInnerHTML={{
        __html: translate('timeline.bundle.error.body'),
      }}
    />
  );

  return <Error title={translate('timeline.bundle.error.headline')} message={message} />;
}

export default SectionsPageError;
