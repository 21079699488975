import React from 'react';
import { arrayOf, string, func, bool, number } from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { STATUS_PENDING } from 'app-constants';
import SectionsLoader from './SectionsLoader';
import CSS from './style.scss';

const renderSectionsFactory = SectionContainer => sectionIds =>
  sectionIds.map(sectionId => <SectionContainer key={sectionId} sectionId={sectionId} />);

function PageSections({
  status,
  sectionIds,
  sectionContainer: SectionContainer,
  showMoreSections,
  canLoadMoreSections,
}) {
  if (status === STATUS_PENDING) {
    return <SectionsLoader sectionsCount={0} />;
  }

  const renderSections = renderSectionsFactory(SectionContainer);

  return (
    <div className={CSS.sections}>
      <InfiniteScroll
        loadMore={showMoreSections}
        hasMore={canLoadMoreSections}
        loader={<SectionsLoader key="loader" sectionsCount={sectionIds.length} />}
      >
        {renderSections(sectionIds)}
      </InfiniteScroll>
    </div>
  );
}

PageSections.propTypes = {
  status: number.isRequired,
  sectionIds: arrayOf(string).isRequired,
  showMoreSections: func.isRequired,
  sectionContainer: func.isRequired,
  canLoadMoreSections: bool.isRequired,
};

export default PageSections;
