import React from 'react';
import { arrayOf, shape, string, func, bool } from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {
  CONTEXT_MENU_ACTION_FOLLOW_CHANNEL,
  CONTEXT_MENU_ACTION_FOLLOW_PROVIDER,
  CONTEXT_MENU_ACTION_FOLLOW_ENTITY,
} from '../../constants';
import SectionFollowButton from '../SectionFollowButton';
import SectionContextMenu from '../SectionContextMenu';
import CSS from './style.scss';

const FOLLOW_OPTIONS = [
  CONTEXT_MENU_ACTION_FOLLOW_PROVIDER,
  CONTEXT_MENU_ACTION_FOLLOW_CHANNEL,
  CONTEXT_MENU_ACTION_FOLLOW_ENTITY,
];

function getFollowOption(options) {
  return options.find(option => FOLLOW_OPTIONS.includes(option.action));
}

function hasFollowOption(options) {
  return Boolean(getFollowOption(options));
}

function SectionActions({ options, onClickOption, isFollowing, ...props }) {
  if (options.length === 0) {
    return null;
  }

  // If the user does not follow the section yet, but has an option to do so, we show just the
  // follow button
  const renderFollowButton = !isFollowing && hasFollowOption(options);
  return (
    <ReactCSSTransitionGroup
      className={CSS.transitionGroup}
      transitionEnterTimeout={200}
      transitionLeaveTimeout={200}
      transitionName={{
        leave: CSS.leave,
        leaveActive: CSS.leaveActive,
        enter: CSS.enter,
        enterActive: CSS.enterActive,
      }}
    >
      {renderFollowButton ? (
        <SectionFollowButton
          key="follow-button"
          onClickOption={onClickOption}
          option={getFollowOption(options)}
          className={CSS.followButton}
          {...props}
        />
      ) : (
        <SectionContextMenu
          key="context-menu"
          onClickOption={onClickOption}
          options={options}
          {...props}
        />
      )}
    </ReactCSSTransitionGroup>
  );
}

SectionActions.propTypes = {
  isFollowing: bool,
  onClickOption: func.isRequired,
  options: arrayOf(shape({ action: string, label: string })).isRequired,
};

SectionActions.defaultProps = {
  isFollowing: true,
};

export default SectionActions;
