// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".heading-3MLe2{font-family:\"GT-Walsheim\",sans-serif;font-size:30px;font-weight:600;letter-spacing:-0.05em;color:#000;text-align:center}.heading-3MLe2:first-letter{text-transform:uppercase}@media (min-width: 600px){.heading-3MLe2{font-size:38px}}@media (min-width: 768px){.heading-3MLe2{font-size:48px}}\n", ""]);
// Exports
exports.locals = {
	"heading": "heading-3MLe2"
};
module.exports = exports;
