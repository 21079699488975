// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".icon-3Jo7s{color:#7f7f7f;width:16px;height:24px;cursor:pointer}.sectionContextDropdown-1kv1s{min-width:215px;max-width:300px}.contextMenu-Z-0iM{align-self:flex-end;margin-right:20px;margin-top:5px}@media (min-width: 600px){.contextMenu-Z-0iM{margin-right:1px}}.optionContent-34-EA{line-height:1.3;display:inline-block;font-family:\"GT-Walsheim\",sans-serif}\n", ""]);
// Exports
exports.locals = {
	"icon": "icon-3Jo7s",
	"sectionContextDropdown": "sectionContextDropdown-1kv1s",
	"contextMenu": "contextMenu-Z-0iM",
	"optionContent": "optionContent-34-EA"
};
module.exports = exports;
