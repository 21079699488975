import React from 'react';
import { func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Button } from '@blendle/lego';
import { translate } from 'instances/i18n';
import CSS from './style.scss';

function enhanceAnalytics(option) {
  return {
    ...option,
    analytics: {
      ...(option.analytics || {}),
      location_in_layout: 'follow-button',
    },
  };
}

function SectionFollowButton({ onClickOption, className, option }) {
  return (
    <Button
      onClick={onClickOption(enhanceAnalytics(option))}
      className={classNames(CSS.followButton, className)}
    >
      {translate('sections.buttons.follow_suggested')}
    </Button>
  );
}

SectionFollowButton.propTypes = {
  onClickOption: func.isRequired,
  option: shape({ action: string, label: string }).isRequired,
  className: string,
};

SectionFollowButton.defaultProps = {
  className: '',
};

export default SectionFollowButton;
