import React from 'react';
import { string, bool } from 'prop-types';
import HTMLWithLinks from 'components/HTMLWithLinks';
import { SECTION_ID_WITH_PROVIDER_CARD_BACKGROUND_COLOR } from 'modules/sectionsPage/constants';
import Alignment from './Alignment';
import Heading from './Heading';
import Body from './Body';
import CSS from './style.scss';

const HeaderSection = ({ title, subtitle, sectionId, areProviderCardsAllowed }) => {
  const useProviderCardBackground =
    areProviderCardsAllowed && sectionId === SECTION_ID_WITH_PROVIDER_CARD_BACKGROUND_COLOR;

  return (
    <div className={useProviderCardBackground && CSS.providerCardBackground}>
      <Alignment>
        <Heading>
          <h2>{title}</h2>
        </Heading>
        {subtitle && (
          <Body>
            <HTMLWithLinks>{subtitle}</HTMLWithLinks>
          </Body>
        )}
      </Alignment>
    </div>
  );
};

HeaderSection.propTypes = {
  title: string.isRequired,
  subtitle: string, // May contain HTML
  sectionId: string,
  areProviderCardsAllowed: bool,
};

HeaderSection.defaultProps = {
  subtitle: undefined,
  sectionId: undefined,
  areProviderCardsAllowed: false,
};

export default HeaderSection;
