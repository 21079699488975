import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import CSS from './style.scss';

function Alignment({ children, className, ...props }) {
  return (
    <div className={classNames(CSS.alignment, className)} {...props}>
      {children}
    </div>
  );
}

Alignment.propTypes = {
  children: node.isRequired,
  className: string,
};

Alignment.defaultProps = {
  className: '',
};

export default Alignment;
